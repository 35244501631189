<template>
  <v-form>
    <v-container>

      <v-alert
        v-model="alert"
        border="left"
        close-text="Close Alert"
        color="orange"
        dark
        dismissible
      >Lo sentimos, no puedes unirte faltando menos de 20 minutos para el inicio de la asesoría.</v-alert>

      <v-dialog v-model="msgbox" persistent width="600px">
        <v-card>
          <v-card-title class="headline" v-text="msgtitulo"></v-card-title>
          <v-card-text class="text--primary" color="black" v-text="msgwarn"></v-card-text>
          <v-card-text class="text--primary" color="black" v-text="msgtexto"></v-card-text>
          
         <v-card-text v-if="msglink != ''">
            <p>COMPARTIR LINK</p>
            <a style="margin-right:20px" target="_blank" :href="' '+msglink+' '" class="text-center">{{msglink}}</a>
            
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn color="primary"
                  dark
                  v-bind="attrs"
                  v-on="on" text @click="copiar">
                    <img width="30" height="30" src="../../assets/copy.png"/>
                </v-btn>
              </template>
              <span>Copiar Link</span>
            </v-tooltip>
        </v-card-text>
          
          <v-card-actions>
            <div class="flex-grow-1"></div>
            <v-btn color="success" text @click="cerrar_mensaje">Continuar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <h2 class="text-center">{{titulo}}</h2>
      <v-toolbar>
        <v-toolbar-title>Datos del Profesor</v-toolbar-title>
        <v-divider
          class="mx-4"
          inset
          vertical
        ></v-divider>
      </v-toolbar>
      <v-card>
        <v-row style="padding:10px">
          <!--v-col cols="12" sm="6" md="3">
            <v-img 
              height="200"
              width="200"
              :src="url_foto"
            ></v-img>
          </v-col-->
          <v-col cols="12" sm="8" md="4">
                <v-text-field
                  readonly
                  v-model="asesoriaData.profesor"
                  label="Nombre"
                  outlined
                ></v-text-field>
                <v-text-field
                  readonly
                  v-model="asesoriaData.universidad"
                  label="Universidad"
                  outlined
                ></v-text-field>
          </v-col>
          <v-col cols="12" sm="8" md="4">
                <v-text-field
                  readonly
                  v-model="asesoriaData.carrera"
                  label="Carrera"
                  outlined
                ></v-text-field>
                <!--v-text-field
                  readonly
                  v-model="asesoriaData.ciclo_actual"
                  type="number"
                  label="Ciclo Actual"
                  outlined
                ></v-text-field-->
          </v-col>

           <v-col cols="12" sm="8" md="4">
            <h4 class="text-center">CALIFICACIÓN</h4>
            <v-row align="center"
              justify="center">
              <v-col
                  cols="12"
                  sm="6"
                  md="8"
                >
                <v-rating
                  v-model="rating"
                  background-color="gray lighten-3"
                  color="gray"
                  large
                  @input="changeRating"
                ></v-rating>
              </v-col>

              <v-col
                  cols="12"
                  sm="6"
                  md="4"
                >
                <span style="font-size:20px" class="text-center">{{rating}}</span>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                  cols="12"
                  sm="6"
                  md="6"
                >
                <p style="font-size:20px" class="text-center">Comentarios: {{data_comentarios.length}}</p>
              </v-col>
              <v-col
                  cols="12"
                  sm="6"
                  md="6"
                >
                <v-btn class="ml-1" height="64%" color="success" @click="dialog_comentarios = true">Comentarios</v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card>
      <v-toolbar>
        <v-toolbar-title>Datos de la Asesoria</v-toolbar-title>
        <v-divider
          class="mx-4"
          inset
          vertical
        ></v-divider>
      </v-toolbar>
      <v-card>
          <v-row>
              <v-col cols="12" sm="8" md="2">
                  <v-list-item>
                    <v-list-item-content>
                        <v-text-field
                          readonly
                          v-model="asesoriaData.fecha"
                          label="Fecha"
                          outlined
                        ></v-text-field>
                    </v-list-item-content>
                  </v-list-item>
              </v-col>
              <v-col cols="12" sm="8" md="2">
                <v-list-item>
                    <v-list-item-content>
                        <v-text-field
                          readonly
                          v-model="asesoriaData.hora"
                          label="Hora Inicio"
                          outlined
                        ></v-text-field>
                    </v-list-item-content>
                </v-list-item>
              </v-col>
              <v-col cols="12" sm="8" md="2">
                <v-list-item>
                    <v-list-item-content>
                        <v-text-field
                          readonly
                          v-model="asesoriaData.hora_fin"
                          label="Hora Fin"
                          outlined
                        ></v-text-field>
                    </v-list-item-content>
                </v-list-item>
              </v-col>
              <v-col cols="12" sm="8" md="2">
                <v-list-item>
                    <v-list-item-content>
                        <v-text-field
                          readonly
                          v-model="asesoriaData.precioFinal"
                          label="Precio Total"
                          outlined
                        ></v-text-field>
                    </v-list-item-content>
                </v-list-item>
              </v-col>
              <v-col cols="12" sm="8" md="4">
                <v-list-item>
                    <v-list-item-content>
                        <v-text-field
                          readonly
                          v-model="asesoriaData.curso"
                          label="Curso"
                          outlined
                        ></v-text-field>
                    </v-list-item-content>
                </v-list-item>
              </v-col>
              <v-col cols="12" sm="8" md="8">
                <v-list-item>
                    <v-list-item-content>
                        <v-textarea
                          readonly
                          v-model="asesoriaData.descripcion"
                          label="Descripción"
                          outlined
                        ></v-textarea>
                    </v-list-item-content>
                </v-list-item>
              </v-col>
              <v-col cols="12" sm="6" md="3">
                <p>Selecciona tu medio de pago</p>
                <v-radio-group v-model="tipo_pago" mandatory>
                  <!--v-radio label="Pago seguro con Tarjeta" value="Tarjeta"></v-radio-->
                  <v-radio label="Pago durante asesoría" value="Asesoria"></v-radio>
                  <!--v-radio label="YAPE" value="YAPE"></v-radio-->
                </v-radio-group>
              </v-col>
          </v-row>
          <v-row justify="center">
            <v-btn @click="validar" color="success">Unirse</v-btn>
            <v-btn :to="{name:'buscar_curso'}" color="error">Regresar</v-btn>
          </v-row>
      </v-card>

      <!--ALERTA Y DIALOGOS -->
      <!-- DIALOGO MOSTRAR COMENTARIOS Y COMENTAR -->
      <v-dialog v-model="dialog_comentarios" max-width="1000px" max-height="600px">
          <v-card>
            <v-card-title>
              <span class="headline">Comentarios para el tutor</span>
            </v-card-title>

            
             <div style="width: 400px; margin-left:50px">
              <v-progress-linear
                :value="starts5"
                color="green"
                height="25"
                
              ><span style="color:white">
                <img width="20" height="20" src="../../assets/star.png">
                    <img width="20" height="20" src="../../assets/star.png">
                    <img width="20" height="20" src="../../assets/star.png">
                    <img width="20" height="20" src="../../assets/star.png">
                    <img width="20" height="20" src="../../assets/star.png">
                </span></v-progress-linear>

              <br>

              <v-progress-linear
                :value="starts4"
                color="blue-grey"
                height="25"
              ><span style="color:white">

                    <img width="20" height="20" src="../../assets/star.png">
                    <img width="20" height="20" src="../../assets/star.png">
                    <img width="20" height="20" src="../../assets/star.png">
                    <img width="20" height="20" src="../../assets/star.png">
              </span>
              </v-progress-linear>

              <br>

              <v-progress-linear
                color="orange"
                :value="starts3"
                height="25"
              ><span style="color:white">
                    <img width="20" height="20" src="../../assets/star.png">
                    <img width="20" height="20" src="../../assets/star.png">
                    <img width="20" height="20" src="../../assets/star.png">


              </span>
              </v-progress-linear>

              
              <br>

              <v-progress-linear
                :value="starts2"
                color="red"
                height="25"
              ><span style="color:white">
                    <img width="20" height="20" src="../../assets/star.png">
                    <img width="20" height="20" src="../../assets/star.png"></span>
              </v-progress-linear>

              
              <br>

              <v-progress-linear
                :value="starts1"
                color="pink"
                height="25"
              ><span style="color:white">
                    <img width="20" height="20" src="../../assets/star.png"></span>
              </v-progress-linear>
            </div>



            <v-card-text>
              <v-container>
                <v-row v-for="(item, i) in data_comentarios" :key="i">
                  <v-col cols="12" sm="6" md="1">
                    <v-img
                      max-height="20"
                      max-width="20"
                      src="../../assets/profile.png"
                    ></v-img>
                  </v-col>
                  <v-col cols="12" sm="6" md="11">
                    <h3>{{item.nombre}} {{item.apellido}} </h3><span>    {{item.fecha}}</span>
                    <p>{{item.mensaje}}</p>                  
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>


            <v-container>
                <v-row>
                  <v-col cols="12" sm="2" md="12">
                    <h4>DEJANOS TU COMENTARIO</h4>
                  </v-col>
                  <v-col cols="12" sm="6" md="12">
                    <v-textarea
                      filled
                      label="COMENTA"
                      auto-grow
                      v-model="comentarios"
                    ></v-textarea>
                  </v-col>
                  <!--  ALERTAS DE COMENTARIO ENVIADO -->
                  <v-col cols="12" sm="6" md="12">
                    <v-alert
                      class="mt-2"
                      :value="alertComentarios"
                      color="pink"
                      dark
                      border="top"
                      icon="mdi-home"
                      transition="scale-transition"
                    >
                      Su comentario se ha registrado. ¡ Muchas gracias !
                    </v-alert>
                  </v-col>
                </v-row>
            </v-container>
            
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn class="ml-1"  color="success" @click="guardarComentario">Enviar</v-btn>
              <v-btn color="success" @click="dialog_comentarios = false">Cerrar</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

      <!--  ALERTAS DE COMENTARIO ENVIADO -->
      <v-alert
        :value="alertRating"
        color="pink"
        dark
        border="top"
        icon="mdi-home"
        transition="scale-transition"
      >
      Su calificación se ha registrado. ¡ Muchas gracias !
      </v-alert>

    </v-container>
  </v-form>
</template>

<script>
import { axiosInstance } from "../../service/index";
import {mapState, mapMutations} from 'vuex'
export default {
  data () {
    return {
      titulo: 'UNIRSE',
      tipo_pago: '',
      rating: 0,
      url_foto: '',
      asesoriaData: {},
      acompanantes: 0,
      alertRating: false,
      alertComentarios: false,
      comentarios: '',
      data_comentarios: [],
      dialog_comentarios: false,
      rulesCantidadAlumnos:
      [
        //minimo 0 acompañantes, maximo 19
        v => (0 <= v) || 'Mínimo: ' + 0 + ' acompañantes',
        v => (v < (20 - this.asesoriaData.cantidad_alumno)) || 'Máximo: ' + (19 - this.asesoriaData.cantidad_alumno) + ' acompañantes'
      ],
      alumnoData: {},
      starts1: 0,
      starts2: 0,
      starts3: 0,
      starts4: 0,
      starts5: 0,
      
      //mensaje
      msgbox: false,
      msgtitulo: '',
      share: '',
      msgwarn: 'Nota: solo puedes cancelar la asesoría si faltan 2 o más horas para que se de.',
      msgtexto: '',
      msglink: '',
      alert: false,
    }
  },
  computed: {
    ...mapState([
      'userAlumno',
      'currentJWT',
      'userProfesor',
      'id_Asesoria',
      'procesoNoTengoCuenta',
      'unirseAsesoria',
    ]),
    idAsesoria() {
      return this.$route.params.id
    }
  },
  created(){
    this.obtenerAsesoria()
    //this.obtenerAlumno()
  },
  methods:{
    ...mapMutations([
      'saveStateToLocalStorage',
      'loadStateFromLocalStorage',
      'resetSate',
    ]),
    mostrar_comentarios(){
      let auxURL = '/api/negocio/getComentariosByProfesorAndCurso?idProfesor='+this.asesoriaData.id_profesor+'&idCurso='+this.asesoriaData.id_Curso
      axiosInstance.get(auxURL)
      .then(res => {
        if(res.data != false){
          this.data_comentarios = res.data
        }
      }).catch(err =>{
        console.log('Error:')
        console.log(err)
      })
    },
    guardarComentario(){
      if (this.validarLogin()) {
        this.alertComentarios = true
        let auxURL = '/api/negocio/putComentaryByCursoAndProfesorAndAlumno?idProfesor='+this.asesoriaData.id_profesor+'&idAlumno='+
        this.currentJWT.id+'&idCurso='+this.asesoriaData.id_Curso+'&comentarios='+this.comentarios
        axiosInstance.put(auxURL)
        .then(res => {
          this.obtenerRating()
          this.comentarios = ''
          this.mostrar_comentarios()
        }).catch(err =>{
          console.log('Error:')
          console.log(err)
        })

        setTimeout(()=>{
          this.alertComentarios=false
        },3000)
      } else {
        me.$store.state.procesoNoTengoCuenta = true;
        me.$store.state.unirseAsesoria = true;
        me.$store.state.id_Asesoria = me.idAsesoria;
        me.saveStateToLocalStorage()
        this.$router.push({name:'alumnologin'})
      }
    },
    obtenerAsesoria(){
      let me = this
      let auxURL = '/api/asesoria/getAsesoriaByIdAsesoriaAbierta?idAsesoria=' + me.idAsesoria
      axiosInstance.get(auxURL)
      .then(res => {
        me.asesoriaData = res.data[0];
        me.asesoriaData.fecha = me.asesoriaData.fecha.replace("T00:00:00.000Z","");
        if (me.asesoriaData.ciclo_actual == 69) {
          me.asesoriaData.ciclo_actual = 'Egresado'
        }
        this.get_foto()

        //mostrar rating 
        this.obtenerRating()
        this.mostrar_comentarios()
      }).catch(err =>{
        console.log('Error:')
        console.log(err)
      })
    },
    get_foto(){
      var me = this
      var auxURL = '/api/asesoria/getFoto?id_profesor=' + me.asesoriaData.id
      axiosInstance.get(auxURL)
      .then(res => {
        me.url_foto = res.data
      }).catch(err =>{
        console.log("Error: "+err)
      })
    },
    validar() {
      let me = this;
      if(me.userProfesor == true){
        console.log("Error: estas logeado como profesor, no puedes unirte a una asesoria")
      }else if(me.userAlumno == false){
        me.$store.state.procesoNoTengoCuenta = true;
        me.$store.state.unirseAsesoria = true;
        me.$store.state.id_Asesoria = me.idAsesoria;
        me.saveStateToLocalStorage()
        me.$router.push({name:'alumnologin'})
      }else if(me.userAlumno == true){
        if (me.tipo_pago == '') {
          swal("Error", "Debe escoger un tipo de pago", "error");
        } else {
          const today = new Date();
          const asesoriaFechaInicio = new Date(`${me.asesoriaData.fecha} ${me.asesoriaData.hora}`);
          if (asesoriaFechaInicio > today) {
            const diffTime = Math.abs(asesoriaFechaInicio - today);
            const diffMins = (diffTime / (1000 * 60));
            if (diffMins > 20) {
              me.unirse();
            } else {
              me.alert = true;
            }
          } else {
            swal("Error", "Lo sentimos, no te puedes unir a la asesoría porque ya inicio", "error");
          }
        }
      }
    },
    unirse() {
      let me = this
      me.obtenerAlumno()
      let auxURL = '/api/asesoria/postUniserseAsesoria?idAsesoria=' + me.idAsesoria + '&idAlumno=' + me.currentJWT.id + '&tipo_pago=' + me.tipo_pago + '&por_cobrar=' + me.asesoriaData.precioFinal  + '&token=' + me.currentJWT.token
      axiosInstance.get(auxURL)
      .then(res => {
        me.msgbox= true;
        me.msgtitulo = res.data;
        me.msgtexto = 'La información respecto al pago se le enviará a su correo'
        me.msglink = this.$hostname + '/alumno/unirse_asesoria/' + me.idAsesoria;
      }).catch(err =>{
        console.log("Error: "+err)
      })
    },
    changeRating(){
      if(this.validarLogin()){
        let auxURL = '/api/negocio/putRatingByIdProfesor?idProfesor='+this.asesoriaData.id_profesor+
        '&rating='+this.rating+'&idAlumno='+this.currentJWT.id;
        axiosInstance.put(auxURL)
        .then(res => {
          this.obtenerRating()
        }).catch(err =>{
          console.log('Error:')
          console.log(err)
        })
      }else{
        me.$store.state.procesoNoTengoCuenta = true;
        me.$store.state.unirseAsesoria = true;
        me.$store.state.id_Asesoria = me.idAsesoria;
        me.saveStateToLocalStorage()
        this.$router.push({name:'alumnologin'})
      }
    },
    obtenerRating(){
      let auxURL = '/api/negocio/getRatingByIdProfesor?idProfesor='+this.asesoriaData.id_profesor
      axiosInstance.get(auxURL)
      .then(res => {
        if(res.data != false){
          var rat = 0;
          var cant = 0;
            this.starts1 = 0;
            this.starts2 = 0;
            this.starts3 = 0;
            this.starts4 = 0;
            this.starts5 = 0;
          for (var i = 0; i<res.data.length; i++){
            rat += (res.data[i].rating*res.data[i].cant)
            cant +=res.data[i].cant;
            if(res.data[i].rating == 1){
              this.starts1 = res.data[i].cant;
            }else if(res.data[i].rating == 2){
              this.starts2 = res.data[i].cant;
            }else if(res.data[i].rating == 3){
              this.starts3 = res.data[i].cant;
            }else if(res.data[i].rating == 4){
              this.starts4 = res.data[i].cant;
            }else if(res.data[i].rating == 5){
              this.starts5 = res.data[i].cant;
            }
          }
          this.rating = rat/cant;
        }
      }).catch(err =>{
        console.log('Error:')
        console.log(err)
      })
    },
    cerrar_mensaje(){
      let me = this;
      me.$router.push({name:'alumnoinicio'})
    },
    obtenerAlumno(){
      var me = this
      var auxURL = '/api/negocio/getInfo?id=' + me.currentJWT.id + '&token=' + me.currentJWT.token + '&tipo=1'
      axiosInstance.get(auxURL)
      .then(res => {
        me.alumnoData.nombre = res.data.nombre + ' ' + res.data.apellido
        me.alumnoData.dni = res.data.dni
        me.alumnoData.celular = res.data.celular
        me.alumnoData.correo = res.data.correo
      }).catch(err =>{
        me.msgtitulo = 'Error del Servidor:';
        me.msgtexto = err.data;
        me.msgbox = true;
      })
    },
    validarLogin() {
      let me = this;
      if(me.userProfesor == true){
        console.log("Error: estas logeado como profesor, no puedes unirte a una asesoria")
      }else if(me.userAlumno == false){
        return false;
      }else if(me.userAlumno == true){
        return true;
      }
    },
    copiar(){
      navigator.clipboard.writeText(this.msglink);
    }
  },
}
</script>

